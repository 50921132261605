import React from "react";
import { useRef } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";

const SERVICE_ID = "service_1hjpxgj";
const TEMPLATE_ID = "template_dogi42a";
const PUBLIC_KEY = "14mTyo2mX3UazpQ6y";

const alertMessage =
  "Your message has been sent!\nWe'll reply to you as soon as we get your message.";

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY).then(
      (result) => {
        console.log(result.text);
        e.target.reset();
        alert(alertMessage);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <div className="contact">
      <h1 className="contact__title">Contact Us</h1>
      <div className="contact__container">
        <form className="contact__form" ref={form} onSubmit={sendEmail}>
          <label className="contact__label">Name</label>
          <input
            className="contact__input"
            type="text"
            placeholder="Your Name"
            name="user_name"
          />
          <label className="contact__label">Email</label>
          <input
            className="contact__input"
            type="email"
            placeholder="Your Email"
            name="user_email"
          />
          <label className="contact__label">Message</label>
          <textarea
            className="contact__textarea"
            placeholder="Message"
            name="message"
          />
          <input className="contact__input" type="submit" value="Send" />
        </form>
      </div>
    </div>
  );
}
