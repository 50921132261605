import React from "react";
import "./Works.css";
import WorkItem from "./WorkItem";

export default function Works() {
  return (
    <div className="works">
      <div className="works__container">
        <h1 className="works__title">Portfolio</h1>
        <div className="works__wrapper">
          <ul className="works__items">
          <WorkItem
              src="images/kcon-la-2023.jpeg"
              title="KCON LA 2023"
              subtitle="ITZY, IVE, RAIN, ZEROBASEONE"
              label="K-POP"
              path="/works"
            />
            <WorkItem
              src="images/xikers.png"
              title="xikers - Comeback Live"
              subtitle="[HOUSE OF TRICKY : HOW TO PLAY] COUNTDOWN LIVE"
              label="K-POP"
              path="/works"
            />
            <WorkItem
              src="images/kcon-jp-2023.png"
              title="KCON JAPAN 2023"
              subtitle="ATEEZ, LE SSERAFIM, ENHYPEN, ZEROBASEONE"
              label="K-POP"
              path="/works"
            />
          </ul>
          <ul className="works__items">
          <WorkItem
              src="images/netflix-theglory.png"
              title="NETFLIX The Glory Fan Event"
              // subtitle="The Glory Cast"
              subtitle="Song Hye-kyo, Kim Eun-sook"
              label="Drama"
              path="/works"
            />
            <WorkItem
              src="images/kcon-th-2023.png"
              title="KCON THAILAND 2023"
              subtitle="(G)I-DLE, iKON, ITZY, Kep1er"
              label="K-POP"
              path="/works"
            />
            <WorkItem
              src="images/wei-firstlove.jpg"
              title="WEi 2022 World Tour - First Love"
              subtitle="WEi"
              label="K-POP"
              path="/works"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}
