import React from "react";
import "./About.css";

export default function About() {
  return (
    <div className="about">
      <div className="about__container">
        <h1 className="about__title">About Us</h1>
        {/* <div className="about__wrapper"></div> */}
        <div className="about__content">
          <p className="about__paragraph-eng">
            Comet Systems specializes in providing real-time translated captions
            to your online live event with Comet Live Caption, our online live
            captioning platform.
          </p>
          <p className="about__paragraph-eng">
            With abundant experience in providing live captions to K-POP,
            K-Culture related events, we developed the most efficient, fast, and
            stable cloud-based platform to serve this purpose.
          </p>
          {/* <p className="about__paragraph-kor">
            코멧 시스템즈(Comet Systems)는 실시간 자막 제작 플랫폼인 코멧 라이브
            캡션(Comet Live Caption)을 통해 온라인 라이브 이벤트에 실시간 다국어
            자막을 제공합니다.
            <br />
            다양한 K-POP, K-Culture 온라인 이벤트에 실시간 자막을 제공한 경험을
            바탕으로 개발한 클라우드 기반의 실시간 자막 제작 플랫폼을 통해 가장
            효율적인 방식으로 빠르고 안정적인 실시간 다국어 자막 서비스를
            제공합니다.
          </p> */}
        </div>
      </div>
    </div>
  );
}
