import React from "react";
import { Button } from "./Button";
import "./Footer.css";
import { Link } from "react-router-dom";

const FACEBOOK_LINK = "https://www.facebook.com/comet.livecaps";
const INSTAGRAM_LINK = "https://www.instagram.com/comet.livecaps/";

function Footer() {
  return (
    <div className="footer-container">
      {/* <section className="footer-subscription">
        <p className="footer-subscription-heading">
          Join the Adventure newsletter to receive our best vacation deals
        </p>
        <p className="footer-subscription-text">
          You can unsubscribe at any time
        </p>
        <div className="input-areas">
          <form>
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              className="footer-input"
            />
            <Button buttonStyle="btn--outline">Subscribe</Button>
          </form>
        </div>
      </section> */}
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>About Us</h2>
            <Link to="/services">How it works</Link>
            <Link to="/works">Careers</Link>
            {/* <Link to="/">Terms of Service</Link> */}
          </div>
          <div className="footer-link-items">
            <h2>Contact Us</h2>
            <Link to="/contact">Contact</Link>
            <Link to="/contact">Support</Link>
            {/* <Link to="/">Sponsorships</Link> */}
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Videos</h2>
            <Link to="/services">Demonstration</Link>
            {/* <Link to="/">Agency</Link> */}
            {/* <Link to="/">Influencer</Link> */}
          </div>
          <div className="footer-link-items">
            <h2>Social Media</h2>
            <Link to={INSTAGRAM_LINK}>Instagram</Link>
            <Link to={FACEBOOK_LINK}>Facebook</Link>
            <Link to="/">Youtube</Link>
            <Link to="/">Twitter</Link>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <img
                className="fab fa-typo3"
                src="logo-white-96.png"
                alt="logo"
                width="32"
                height="32"
              />
              Comet Systems
              {/* <i className="fab fa-typo3"></i> */}
            </Link>
          </div>
          <small className="website-rights">comet.livecaps@gmail.com</small>
          <small className="website-rights">Comet Systems © 2023</small>
          <div className="social-icons">
            <Link
              className="social-icon-link facebook"
              to={FACEBOOK_LINK}
              target="_blank"
              aria-label="Facebook"
            >
              <i className="fab fa-facebook-f"></i>
            </Link>
            <Link
              className="social-icon-link instagram"
              to={INSTAGRAM_LINK}
              target="_blank"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram"></i>
            </Link>
            <Link
              className="social-icon-link youtube"
              to="/"
              target="_blank"
              aria-label="Youtube"
            >
              <i className="fab fa-youtube"></i>
            </Link>
            <Link
              className="social-icon-link twitter"
              to="/"
              target="_blank"
              aria-label="Twitter"
            >
              <i className="fab fa-twitter"></i>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
