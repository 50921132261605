import React from "react";
import CardItem from "./CardItem";
import "./Cards.css";

function Cards() {
  return (
    <div className="cards">
      <h1>Check out our recent events</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
          <CardItem
              src="images/kcon-la-2023.jpeg"
              title="KCON LA 2023"
              subtitle="ITZY, IVE, RAIN, ZEROBASEONE"
              label="K-POP"
              path="/works"
            />
            <CardItem
              src="images/xikers.png"
              title="xikers - Comeback Live"
              subtitle="[HOUSE OF TRICKY : HOW TO PLAY] COUNTDOWN LIVE"
              label="K-POP"
              path="/works"
            />
            <CardItem
              src="images/kcon-jp-2023.png"
              title="KCON JAPAN 2023"
              subtitle="ATEEZ, LE SSERAFIM, ENHYPEN, ZEROBASEONE"
              label="K-POP"
              path="/works"
            />
          </ul>
          <ul className="cards__items">
          <CardItem
              src="images/netflix-theglory.png"
              title="NETFLIX The Glory Fan Event"
              // subtitle="The Glory Cast"
              subtitle="Song Hye-kyo, Kim Eun-sook"
              label="Drama"
              path="/works"
            />
            <CardItem
              src="images/kcon-th-2023.png"
              title="KCON THAILAND 2023"
              subtitle="(G)I-DLE, iKON, ITZY, Kep1er"
              label="K-POP"
              path="/works"
            />
            <CardItem
              src="images/wei-firstlove.jpg"
              title="WEi 2022 World Tour - First Love "
              label="K-POP"
              path="/wokrs"
            />
            {/* <CardItem
              src="images/img-works-1.jpeg"
              title="Connect with the global fans with real-time subtitles"
              label="LIVE"
              path="/works"
            /> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
