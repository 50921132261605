import React from "react";
import "./Services.css";

export default function Services() {
  return (
    <div className="services">
      {/* <h1 className="services__title">Services</h1> */}
      <div className="services__container">
        <div className="services__livecaption">
          <h1 className="livecaption__title">Live Captioning</h1>
          {/* <div className="services__description">
            Real-time subtitling service in multiple languages in any kind of
            online live event you can imagine.
          </div> */}
          <div className="services__wrapper">
            <img
              src="images/services-basic3.png"
              alt="yt-simple"
              className="services__item__img-left"
            />
            <div className="services__description">
              Real-time subtitling service for any kind of live event
              broadcasted online!
            </div>
          </div>
          <div className="services__wrapper-rev">
            <div className="services__description">
              Simultaneous subtitling service in multiple languages to widen
              your fanbase.
            </div>
            <img
              src="images/services-multilang2-crop.png"
              alt="multi-lang"
              className="services__item__img-right"
            />
          </div>
          <div className="services__wrapper">
            <img
              src="images/services-multilang-ch2-crop.png"
              alt="multi-lang-ch"
              className="services__item__img-left"
            />
            <div className="services__description">
              Various design options to broadcast with your own customized-style
              subtitles in any desired platform or device.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
