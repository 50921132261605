import React from "react";
import { Link } from "react-router-dom";

function WorkItem(props) {
  return (
    <>
      <li className="works__item">
        <Link className="works__item__link" to={props.path}>
          <figure className="works__item__pic-wrap" data-category={props.label}>
            <img
              src={props.src}
              alt="Travel Image"
              className="works__item__img"
            />
          </figure>
          <div className="works__item__info">
            <h5 className="works__item__title">{props.title}</h5>
            <h2 className="works__item__subtitle">{props.subtitle}</h2>
          </div>
        </Link>
      </li>
    </>
  );
}

export default WorkItem;
